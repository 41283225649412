import {useState} from 'react';

function Confirmacion({hide,aceptar,msg}) {

  const [loading, setLoading] = useState(false);


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='modal-title '>Confirmacion</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide} disabled={loading}></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex'>
                <h5 className='mx-3'>{msg}</h5>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={() => {setLoading(true);aceptar()}} disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confirmacion;