import React from 'react';
import {useState} from 'react';
import '../hojas-de-estilo/Poliza.css';
import { AiOutlineClose,AiOutlineCopy } from "react-icons/ai";
import { Link } from 'react-router-dom';

function Poliza({ id, fecha, descripcion, asientos, completada, eliminarPoliza}) {

  return (
    // <div className={(completada ? 'tarea-contenedor completada' : 'tarea-contenedor')+' shadow-sm'}>
      <Link to={'/edit?id='+id}>
        <div 
          className='tarea-texto  text-body'>
          <div className='tarea-header text-body-emphasis'>
              <h2 className='' style={{marginBottom:'0px'}}>{fecha + ' ' + descripcion}</h2>
            <div 
              className='tarea-contenedor-iconos'>
              <Link onClick={e => e.stopPropagation()} to={'/add?id='+id}><AiOutlineCopy style={{color: 'gray'}} className='tarea-icono' /></Link>
            </div>
            <div 
              className='tarea-contenedor-iconos'>
              <AiOutlineClose onClick={(e) => {e.preventDefault();e.stopPropagation();eliminarPoliza({id:id,descripcion:descripcion});}} style={{color: 'gray'}} className='tarea-icono' />
            </div>
          </div>
          <div style={{'minHeight': '30px',whiteSpace: 'pre-line'}}>
            {asientos}
          </div>
        </div>
      </Link>
    // </div>
  );
}

export default Poliza;