import {useState} from 'react';

function AgregarEmpresa({hide,agregar}) {

  const [nombre, setNombre] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const result = event.target.value.replace(/[^a-z 0-9]/gi, '');

    setNombre(result);
  };

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Agregar empresa</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide} disabled={loading}></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex'>
                <h4 className='mx-3'>Nombre</h4>
                <input type='text' value={nombre} onChange={handleChange}  maxLength='20'/>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={() => {setLoading(true);agregar(nombre)}} disabled={loading}>
              {loading && <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
              Agregar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgregarEmpresa;