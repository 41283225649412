import {Link} from 'react-router-dom';
import '../css/Header.css';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineViewList,HiOutlineDocumentReport } from "react-icons/hi";


function HeaderComercial({address,connectNami,connectEternl,connectEternlMobile,emptyAllPunks}) {

  return (
    <div className="container-lg">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <Link to="/" className="d-flex align-items-center me-auto link-body-emphasis text-decoration-none">
          <span className="fs-4">MiConta</span>
        </Link>

        <Link type='button' to='/polizas' className='btn btn-primary btn-lg px-4 me-md-2'>Iniciar</Link>
      </header>
    </div>
  );
}

export default HeaderComercial;