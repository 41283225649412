import {Link} from 'react-router-dom';


function Index() {

  return (
    <div>
      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row-reverse align-items-center g-5'>
          <div className='col-12 col-sm-6'>
            <h1 className='display-5 fw-bold text-body-emphasis lh-1 mb-3'>MiConta</h1>
            <p className='lead'>El sistema contable de partida doble en la nube mas facil del mercado. MiConta es ideal para contadores independientes y finanzas personales.</p>
          </div>
          <div className='col-12 col-sm-6'>
            <img src='/imgs/devices.png' className='d-block mx-auto img-fluid rounded-3' width='500' height='500' loading='lazy'/>
          </div>
        </div>
      </div>




      <div class="container  col-xxl-8  px-4 py-5 mt-5 bg-body shadow-sm rounded-3" id="icon-grid">
        <h2 class="pb-2 border-bottom">Características</h2>

        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#bootstrap"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Fácil de usar</h3>
              <p>Sin complicaciones y sin funciones innecesarias.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#tools"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Potente</h3>
              <p>Toda la potencia de un sistema contable de partida doble.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#cpu-fill"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">En la nube</h3>
              <p>Accede desde cualquier dispositivo con coneccion a internet.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#calendar3"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Tu información</h3>
              <p>Descarga toda tu informacion con un simple clic.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#home"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Seguro</h3>
              <p>Alojado en el centro de datos de Google App Engine.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#speedometer2"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Portátil</h3>
              <p>Optimizado para computadoras, tabletas y telefonos inteligentes.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#toggles2"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Soporte técnico</h3>
              <p>Si necesitas ayuda tenemos soporte via chat o correo electronico.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#geo-fill"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Multi moneda</h3>
              <p>Soporte para cualquier moneda, criptomoneda o incluso acciones.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#tools"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Amplia documentación</h3>
              <p>Videos desde teoria de contabilidad hasta funciones avanzadas de MiConta.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#tools"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Multi empresa</h3>
              <p>Manten todas las empresas que gestiones, en un solo sistema.</p>
            </div>
          </div>
          <div class="col d-flex align-items-start">
            {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#tools"></use></svg> */}
            <div>
              <h3 class="fw-bold mb-0 fs-4 text-body-emphasis">Sin plazos forzosos</h3>
              <p>Sin cargos automaticos, renueva cuando queiras, cancela cuando quieras.</p>
            </div>
          </div>
        </div>
      </div>


      
      <div className="container py-3">
        <header>
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 className="display-4 fw-normal text-body-emphasis">Precios</h1>
            <p className="fs-5 text-body-secondary">Precios en dolares americanos. Puedes pagar con PayPal desde cualquier parte del mundo.</p>
          </div>
        </header>

        <main>
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Base</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$9.99<small className="text-body-secondary fw-light">/mes</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>1 usuario</li>
                    <li>3 empresas</li>
                    <li>Soporte tecnico</li>
                  </ul>
                  <a href='/pay-base' type="button" className="w-100 btn btn-lg btn-primary">Comprar</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Usuario adicional</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$4.99<small className="text-body-secondary fw-light">/mes</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>1 usuario</li>
                    <li>---</li>
                    <li>---</li>
                  </ul>
                  <button type="button" className="w-100 btn btn-lg btn-primary" disabled='true'>Proximamente</button>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">Empresa adicional</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title">$4.99<small className="text-body-secondary fw-light">/mes</small></h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li>1 empresa</li>
                    <li>---</li>
                    <li>---</li>
                  </ul>
                  <button type="button" className="w-100 btn btn-lg btn-primary" disabled='true'>Proximamente</button>
                </div>
              </div>
            </div>
          </div>
        </main>


        <div class="px-4 py-5 my-5 text-center">
          <h1 class="display-5 fw-bold text-body-emphasis">Prueba gratis</h1>
          <div class="col-lg-6 mx-auto">
            <p class="lead mb-4">Puedes probar MiConta sin restricciones y sin datos de pago. El periodo de prueba es de un mes y tienes acceso a todas las funciones y caracteristicas del sistema, incluye un usuario y tres empresas.</p>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Link to='/alta'><button type="button" class="btn btn-primary btn-lg px-4 gap-3">Probar un mes gratis</button></Link>
            </div>
          </div>
        </div>

        <footer class="pt-4 my-md-5 pt-md-5">
        <h2 class="pb-2 border-bottom">Documentación</h2>

          <div class="row">
            <div class="col-12 col-md">
              <img class="mb-2" src="/logo192.png" alt="" width="32" height="32"/>
              <small class="d-block mb-3 text-body-secondary">© 2024</small>
            </div>
            <div class="col-6 col-md">
              <h5>Para no contadores</h5>
              <ul class="list-unstyled text-small">
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/PJxg29DeBBE?si=fzk4-LZE9YyWDhRX">El sistema de partida doble</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/gUCF3z9xtas?si=TmMtZi1Xu9Glbzbu">Polizas</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/jABLCGLn8Tg?si=O2hZlrUht0OCT1uY">Polizas ejemplos</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/W4fxLiFtXnA?si=LRpw-gfj2r6N_LBo">Cuentas contables</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank'v href="https://youtu.be/pQsa5q4EdEs?si=nzt2Sk0Woml-OWVB">Estados financieros</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/t8NgZk9LKgU?si=p0CoC0OCodgGq-b5">Ejemplo practico</a></li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>Para contadores</h5>
              <ul class="list-unstyled text-small">
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/PeRaiHgRd8c?si=aNse6XkK5K7D8HzF">Presentacion</a></li>
              </ul>
            </div>
            <div class="col-6 col-md">
              <h5>MiConta</h5>
              <ul class="list-unstyled text-small">
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/Wgal515isgc?si=8Eq4Q2sfZNZlJy3_">Cuentas contables</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/9JBkiJotrq4?si=sb9LXYpGJ19cpofA">Polizas</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/8cApDMLUp8g?si=TEBnZQfMEm0IDV60">Estados financieros</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/9DcvINS6ADM?si=8BewDUmR2FvbYF6J">Auxiliar</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/Xm8n8d7mY54?si=hvI5S7cXCXxvJ2w5">Multi monedas</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/EXNY0Vt8Wls?si=ME42duz6YhwbdP4N">Poliza de cierre</a></li>
                <li class="mb-1"><a class="link-secondary text-decoration-none" target='_blank' href="https://youtu.be/Na29srEyE8M?si=q_hGjj1zXzvPC-6X">Graficos</a></li>
              </ul>
            </div>
          </div>
        </footer>
      </div>

    </div>
  );
}

export default Index;