import {useEffect, useState} from 'react';

function ChartEdition({hide,editar,id}) {
  const [nombre, setNombre] = useState(null);
  const [filtro, setFiltro] = useState(null);
  const [monto, setMonto] = useState('cantidad');
  const [invertir, setInvertir] = useState(false);
  const [acumulado, setAcumulado] = useState(false);



  function callEditar(){
    if (filtro)
      editar(nombre,filtro,monto,acumulado,invertir);
    else
      alert('Debe espesificar un filtro');
  }


  useEffect(() => {
      window.$.ajax({
        url: '/api/chartData',
        type: 'GET',
        dataType: "json",
        data: {id: id,
        },
        success: function (data) {
          console.log(data);
          setNombre(data.nombre);
          setFiltro(data.filtro);
          setMonto(data.monto);
          setInvertir((data.invertir?true:false));
          setAcumulado((data.acumulado?true:false));
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
          window.location='/login';
          if (jqXHR.status==402)
          window.location='/pay-base';
        },
      });
  }, []);

  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Edicion de grafico</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
            <div className="bg-body-secondary  shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
              <div className='row'>
                <div className='col-12 col-md-12 col-lg'>
                    <label className='pt-1' style={{marginRight:'10px'}}>Nombre </label>
                    <input autoComplete='off' onChange={(e) => {setNombre(e.target.value)}} className='form-control form-control-lg input w-100' defaultValue={nombre}/>
                </div>
                <div className='col-12 col-md-12 col-lg'>
                    <label className='pt-1' style={{marginRight:'10px'}}>Filtro </label>
                    <input autoComplete='off' onChange={(e) => {setFiltro(e.target.value)}} className='form-control form-control-lg input w-100' defaultValue={filtro}/>
                </div>
                <div className='col-6 col-md'>
                  <label className='pt-1' style={{marginRight:'10px'}}>Monto </label>
                  <select className='form-select form-select-lg' value={monto} onChange={e => {setMonto(e.target.value)}} aria-label='.form-select-lg example'>
                  <option value='cantidad'>Cantidad</option>
                  <option value='costo'>Costo</option>
                  <option value='mercado'>Mercado</option>
                  </select>
                </div>
                {id>4 && <div className='col-3 col-md-3 col-lg-2'>
                  <label className='pt-1' style={{marginRight:'10px'}}>Acumulado</label>
                  <input class="form-control form-check-input" style={{width:'40px',height:'40px'}} type="checkbox" value={acumulado} checked={acumulado} onChange={(e) => {setAcumulado(e.target.checked)}}/>
                </div>}
                <div className='col-3 col-md-3 col-lg-2'>
                  <label className='pt-1' style={{marginRight:'10px'}}>Invertir</label>
                  <input class="form-control form-check-input" style={{width:'40px',height:'40px'}} type="checkbox" value={invertir} checked={invertir} onChange={(e) => {setInvertir(e.target.checked)}}/>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={callEditar}>Aceptar</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ChartEdition;