import {useState,useEffect} from 'react';
import { useSearchParams,Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FormPoliza from './FormPoliza';
import FileUploader from './FileUploader';
import { AiOutlineRight,AiOutlineLeft } from "react-icons/ai";


function FormPolizas({allPolizas,cuentas,method, cargarPolizas,cargarCuentas}) {
  const [polizas, setPolizas] = useState([{id:uuidv4(),fecha:"",descripcion:"",asientos:[{id:uuidv4(),cuenta:'',monto:null}]}]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const id = searchParams.get('id');


  useEffect(() => {
    if (searchParams.get('id')!=null && allPolizas!=null){
      const index = allPolizas.findIndex(poliza => parseInt(searchParams.get('id')) === poliza.id);
      if (index-1>=0)
        setNext(allPolizas[index-1].id);
      else
        setNext(null);
      if (index+1<allPolizas.length)
        setPrev(allPolizas[index+1].id);
      else
        setPrev(null);
    }
  }, [id]);


  return (
    <div className='container-lg'>

      <div style={{display:'flex'}}>
        <FileUploader className="w-100" setPolizas={setPolizas}></FileUploader>
        {method==="PUT" && <Link style={{pointerEvents: prev != null ? '' : 'none'}} to={'/edit?id='+prev}><AiOutlineLeft  className='m-3 tarea-icono'/></Link>}
        {method==="PUT" && <Link style={{pointerEvents: next != null ? '' : 'none'}}   to={'/edit?id='+next}><AiOutlineRight className='m-3 tarea-icono'/></Link>}
      </div>
      {
      polizas.map((item) =>
        <FormPoliza
          key={item.id}
          id={item.id} 
          poliza={item}
          cuentas={cuentas}
          method={method}
          cargarPolizas={cargarPolizas}
          cargarCuentas={cargarCuentas}
        >
        </FormPoliza>
      ) 
    }
    </div>
  );
}

export default FormPolizas;