import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import '../hojas-de-estilo/LogIn.css';

function LogIn() {
  useEffect(() => {
    window.$("form").submit(function (event) {
      var formData = {
        user: window.$("#user").val(),
        pass: window.$("#pass").val(),
      };

      window.$.ajax({
        type: "POST",
        url: "/api/login",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(formData),
        dataType: "json",
      }).done(function (data) {
        console.log(data)
        if (data.status=='ok'){
          if (data.renovar){
            window.$('#alert-modal-body').html("Su cuenta esta vencida, es necesario renovar para poder acceder a su cuenta.");
            window.$('#alert-modal').modal('show');
            window.$('#alert-modal-btn').on( "click", function() {
              window.location='/pay-base';
            } );
          }else
            window.location='/charts';
        }else{
          appendAlert(data.msg);
        }
      });
  
      event.preventDefault();
    });
  }, []);

  const appendAlert = (message) => {
    const alertPlaceholder = document.getElementById('liveAlertPlaceholder');
    alertPlaceholder.innerHTML = "";
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-danger alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')

    alertPlaceholder.append(wrapper)
  }

  return (
  <div className="d-flex align-items-center py-4 bg-body-tertiary">
    <main className="form-signin w-100 m-auto">
      <form>
        <img className="mb-4" src="/logo192.png" alt="" width="75" height="75"/>
        <h1 className="h3 mb-3 fw-normal">Inicio de sesión</h1>

        <div id="liveAlertPlaceholder"></div>

        <div className="form-floating">
          <input type="text" className="form-control" id="user" placeholder="name@example.com"/>
          <label htmlFor="user">Usuario</label>
        </div>
        <div className="form-floating">
          <input type="password" className="form-control" id="pass" placeholder="Password"/>
          <label htmlFor="pass">Contraseña</label>
        </div>

        {/* <div className="form-check text-start my-3">
          <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault"/>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Recordar sesión
          </label>
        </div> */}
        <button className="btn btn-primary w-100 py-2 mt-5" type="submit">Iniciar</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2024</p>
      </form>
    </main>
  </div>
  );
}

export default LogIn;