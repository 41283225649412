import Asientos from './Asientos';
import {useState,useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {formatFecha} from './Funciones'
import AutoBalance from './Modals/AutoBalance';

function FormPoliza({cuentas,poliza,method,cargarPolizas,cargarCuentas}) {

  const [id, setId] = useState(poliza.id);
  const [asientos, setAsientos] = useState(poliza.asientos);
  const [fecha, setFecha] = useState(poliza.fecha);
  const [descripcion, setDescripcion] = useState(poliza.descripcion);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totals, setTotals] = useState([]);
  const [showAutoBalance, setShowAutoBalance] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updating, setUpdating] = useState(false);

  
  function addToAmountArray(monto, balance){
    const moneda = (monto.costo!=null && monto.costo!=''?'$':monto.moneda);
    var decimales=2;
    if (moneda!='$' && monto.decimales)
      decimales=monto.decimales;

    const val = balance.find((e) => e.moneda==moneda);
    if (val != null){
      val.monto = parseFloat(val.monto)+(parseFloat(monto.monto)*(monto.costo==null || monto.costo==''?1:parseFloat(monto.costo)));
      if (decimales>val.decimales)
        val.decimales=decimales;
    }else{
      balance.push({decimales:decimales,moneda:moneda,monto:(parseFloat(monto.monto)*(monto.costo==null || monto.costo==''?1:parseFloat(monto.costo)))});
    }
  }
  
  function setStrMonto(item){
    if (item.moneda==null)
      return;
  
    var montoFormateado = item.monto.toFixed((item.decimales==null?2:item.decimales));

    if (item.moneda.length==1)
      item.strMonto=item.moneda+" "+montoFormateado;
    else
      item.strMonto=montoFormateado+" "+item.moneda;

    return item;
  }

  function esCero(monto,decimales){
    if (monto.toFixed(decimales)==0)
      return true;
    else
      return false;
  }

  function guardar(){
    calc();
    for (const t of totals){
      if (!esCero(t.monto,t.decimales)){
        window.$('#alert-modal-body').html("La poliza no esta balanceada.");
        window.$('#alert-modal').modal('show');
        return;
      }
    }

    setUpdating(true);
    window.$.ajax({
      url: '/poliza',
      type: method,
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        id: id,
        fecha: fecha,
        descripcion: descripcion,
        asientos: asientos
      }),
      success: function (data) {
        if (data.result=='ok'){
          if (method=='POST'){
            setAsientos([{id:uuidv4(),cuenta:"",monto:null}]);
            setFecha("");
            setDescripcion("");
          }
          cargarPolizas();
          cargarCuentas();
          setUpdate(false);
        }else{
          window.$('#alert-modal-body').html(data.msg);
          window.$('#alert-modal').modal('show');
        }
        setUpdating(false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }

  useEffect(() => {
    if (searchParams.get('id')!=null){
      window.$.ajax({
        url: '/api/asientos',
        type: 'GET',
        dataType: "json",
        data: {poliza: searchParams.get('id'),
        },
        success: function (data) {
          setAsientos(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
          window.location='/login';
          if (jqXHR.status==402)
          window.location='/pay-base';
        },
      });
      window.$.ajax({
        url: '/api/poliza',
        type: 'GET',
        dataType: "json",
        data: {id: searchParams.get('id'),
        },
        success: function (data) {
          setId(data.id);
          setDescripcion(data.descripcion);
          setFecha(formatFecha(data.fecha));
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
          window.location='/login';
          if (jqXHR.status==402)
          window.location='/pay-base';
        },
      });
    }else
      calc();
  }, [searchParams])

  useEffect(() => {
      calc();
      window.$('.date-own').datepicker({
        dateFormat: 'dd/mm/yy',
        showButtonPanel: true,
        onSelect: (e) => {setFecha(e);setUpdate(true);}
      });
  }, [asientos])


  function calc(){
    const tot=[];
    for (const asiento of asientos){
      if (asiento.monto!=null){
        if (!isNaN(asiento.monto.monto))
          addToAmountArray(asiento.monto,tot);
      }
    }

    for (const total of tot)
      setStrMonto(total);
    setTotals(tot);
    // console.log(asientos)
    // console.log(tot)

  }

  function sort(items,metodo){
    switch (metodo){
      case 'peps':
        return sortPEPS(items);
      case 'ueps':
        return sortUEPS(items);
      case 'menor':
        return sortMenorMayor(items);
      case 'mayor':
        return sortMayorMenor(items);
    }
  }

  function sortMayorMenor(items){
    return items.sort(( a, b ) => {
      if ( a.costo < b.costo ){
        return 1;
      }
      if ( a.costo > b.costo ){
        return -1;
      }
      return 0;
    });
  }
  function sortMenorMayor(items){
    return items.sort(( a, b ) => {
      if ( a.costo < b.costo ){
        return -1;
      }
      if ( a.costo > b.costo ){
        return 1;
      }
      return 0;
    });
  }
  function sortPEPS(items){
    return items.sort(( a, b ) => {
      if ( new Date(a.fecha).getTime() < new Date(b.fecha).getTime() ){
        return -1;
      }
      if ( new Date(a.fecha).getTime() > new Date( b.fecha).getTime() ){
        return 1;
      }
      return 0;
    });
  }
  function sortUEPS(items){
    return items.sort(( a, b ) => {
      if ( new Date(a.fecha).getTime() < new Date(b.fecha).getTime() ){
        return 1;
      }
      if ( new Date(a.fecha).getTime() > new Date(b.fecha).getTime() ){
        return -1;
      }
      return 0;
    });
  }

  function autoAgregar(filtro,metodo,max,coin){
    setShowAutoBalance(false);
    window.$.ajax({
      url: '/api/balance',
      type: 'GET',
      dataType: "json",
      data: {nivel: 0, filtro:filtro, monto:'lotes', invertir:true},
      success: function (data) {
        var tmpAsientos = [];
        if (asientos.length>1 || (asientos[0].cuenta && asientos[0].monto))
          tmpAsientos = [...asientos];
        var id=123123+tmpAsientos.length;
        for (const item of data){
          var total=0;
          for (const amount of sort(item.balance,metodo)){
            // console.log(max+' '+total+' '+(total+Math.abs(amount.monto)))
            if (coin == null || amount.moneda.includes(coin)){
              if (max>0 && (total+Math.abs(amount.monto)) >= max){
                tmpAsientos.push({id:id,cuenta:item.cuenta,monto:(amount.moneda==null || amount.moneda=='$'?"$"+((max-total)*(amount.monto<0?-1:1)).toFixed(amount.decimales):((max-total)*(amount.monto<0?-1:1)).toFixed(amount.decimales)+' '+amount.moneda)});
                total+=Math.abs(parseFloat((max-total).toFixed(amount.decimales)));
                break;
              }else{
                tmpAsientos.push({id:id,cuenta:item.cuenta,monto:(amount.strMonto!=null?amount.strMonto:"$"+amount.monto.toFixed(amount.decimales))});
                total+=Math.abs(parseFloat(amount.monto.toFixed(amount.decimales)));
              }
              id=id+1;
            }
          }
        }
        setAsientos(tmpAsientos);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }

  return (
    <div className="container-lg">
      <div className="row bg-body-secondary shadow-sm rounded-3 py-3 mb-5">
        <div className="mb-3">
          <label htmlFor="fecha" className="form-label">Fecha</label>
          <input type="text" autoComplete="off" pattern="\d{2}/\d{2}/\d{4}" className="form-control date-own" id="fecha" value={fecha} onChange={(e) => {setFecha(e.target.value);setUpdate(true);}}/>
        </div>
        <div className="mb-3">
          <label htmlFor="desc" className="form-label">Descripción</label>
          <input type="text" autoComplete="off" className="form-control" id="desc" value={descripcion} onChange={(e) => {setDescripcion(e.target.value);setUpdate(true);}}/>
        </div>

        <Asientos autoAgregar={() => setShowAutoBalance(true)} setUpdate={setUpdate} cuentas={cuentas} asientos={asientos} setAsientos={setAsientos} calc={calc}></Asientos>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-7  col-xl-8'/>
          <div className='col-8 col-sm-9 col-md-4 col-lg-3 col-xl-3'>
            {
              totals.map((item,index) =>
                <div  className='text-end' key={index}>{item.strMonto}</div>
              ) 
            }
          </div>
          <div className='col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1'/>
        </div>

        <div className='container'>
        <button onClick={e => guardar()} className="btn btn-primary w-100 mt-5" disabled={!update || updating}>{(updating?<><span className="spinner-border spinner-border-sm" aria-hidden="true"></span><span role="status"> Guardando...</span></>:(method=='POST'?'Guardar':'Actualizar'))}</button>
        </div>
      </div>


      {showAutoBalance && <AutoBalance  hide={() => setShowAutoBalance(false)} autoAgregar={autoAgregar}></AutoBalance>}
    </div>
  );
}

export default FormPoliza;