import { AiOutlineCloseCircle,AiOutlinePlusCircle } from "react-icons/ai";
import {useState,useEffect} from 'react';
import Autosuggest from 'react-autosuggest';
import '../hojas-de-estilo/Asiento.css';

function Asiento({id,asiento,cuentas,eliminar,agregar,calc,setUpdate}) {

  const [cuenta, setCuenta] = useState(asiento.cuenta);
  const [monto, setMonto] = useState((asiento.monto!=null?(asiento.monto.strFullMonto!=null?asiento.monto.strFullMonto:asiento.monto):''));

  // window.$( "#"+id ).autocomplete({
  //   source: cuentas,
  //   select: function( event, ui ) {asiento.cuenta=ui.item.value},
  // });

  function setStrMonto(item){
    if (item.moneda==null)
      return;
  
    var montoFormateado = item.monto;
    if (item.moneda=='$'){
      montoFormateado=item.monto.toLocaleString("en-US");
    }else if (item.decimales!=null)
      montoFormateado=parseFloat(item.monto).toFixed(item.decimales);
    else
      montoFormateado=item.monto.toLocaleString("en-US");
  
  
    if (item.moneda.length==1)
      item.strMonto=item.moneda+" "+montoFormateado;
    else
      item.strMonto=montoFormateado+" "+item.moneda;
  
    return item;
  }

  function setStrMontoInverted(item){
    if (item.moneda==null)
      return;
  
    var montoFormateado = (item.monto*-1);
    if (item.moneda=='$'){
      montoFormateado=(item.monto*-1).toLocaleString("en-US");
    }else if (item.decimales!=null)
      montoFormateado=parseFloat((item.monto*-1)).toFixed(item.decimales);
    else
      montoFormateado=(item.monto*-1).toLocaleString("en-US");
  
  
    if (item.moneda.length==1)
      item.strMontoInverted=item.moneda+" "+montoFormateado;
    else
      item.strMontoInverted=montoFormateado+" "+item.moneda;
  
    return item;
  }
  
  function getMoneda(str){
    var result="";
    for (let i = 0; i < str.length; i++) {
      if (isNaN(str[i]) && str[i]!='-' && str[i]!=',' && str[i]!='.')
        result=result+str[i];
    }
    if (result=="")
      return "$"
    else
      return result.trim();
  }
  function getMonto(str){
    if (str==null)
		  return null;
    var result="";
    for (let i = 0; i < str.length; i++) {
      if (!isNaN(str[i]) || str[i]=='-'  || str[i]=='.')
        result=result+str[i];
    }
    return result;
  }
  function getNoDecimales(str){
    if (str==null || str.indexOf('.') < 0)
      return 0;

    const partes = str.replace(/ /g,'').split('.');
    return partes[1].length;
  }

  //Str = $1,500 o 1.5 ETH @ $30,000
  function parseMonto(str){
    if (str==null || str=="" || str==" ")
      return null;
      //partes[0] = monto y moneda ($1500) o (1.5 ETH)
      //partes[1] = costo
      var partes,monto,costo=null,precio=null;
    if (str.includes('(@)')){
      partes = str.toString().split('(@)');
    }else if  (str.includes('@')){
      partes = str.toString().split('@');
      precio=partes[1];
      costo=partes[1];
    }else
      partes = [str];
  
    if (partes[0].indexOf('{')>-1){
      partes = partes[0].toString().split('{');
      monto=partes[0];
      costo=partes[1].replace('}','');
    }else{
      monto=partes[0];
      costo=partes[1];
    }
      
  
    return setStrMonto({strFullMonto:str,moneda:getMoneda(monto),decimales:getNoDecimales(getMonto(monto)),monto:getMonto(monto),costo:getMonto(costo),costoDecimales:getNoDecimales(getMonto(costo)),precio:getMonto(precio)});
    }


    const handleChange = event => {
      const result = event.target.value.replace(/[^a-z:0-9]/gi, '');
  
      asiento.cuenta=result;
      setCuenta(result);
    };





    const [cuentasFiltradas, setCuentasFiltradas] = useState([]);  
    const onSuggestionsFetchRequested = ({value}) => {
      var filtered = cuentas.filter((c) => c.nombre.toLowerCase().includes(value.toLowerCase()));
      if (filtered.length>15)
        filtered=filtered.slice(0, 15);
      for (const item of filtered){
        if (item.strMonto==null){
          setStrMonto(item);
          setStrMontoInverted(item);
        }
      }
      setCuentasFiltradas(filtered);
    }
    const renderSuggestion = (suggestion) => (
      <div className='sugerencia'>
        <label onClick={()=>{setUpdate(true);setCuenta(suggestion.nombre)}}>{suggestion.nombre}</label> <label style={{cursor:'pointer'}} onClick={()=>{setUpdate(true);setCuenta(suggestion.nombre);asiento.monto=parseMonto(suggestion.strMontoInverted);setMonto(suggestion.strMontoInverted);calc()}} className="ps-3">{suggestion.strMonto}</label>
        {/* {suggestion.nombre+' / '+suggestion.strMonto} */}
      </div>
    );
    const onChange=(e,{newValue})=>{
      const result = newValue.replace(/[^a-z:0-9-_]/gi, '');
  
      asiento.cuenta=result;
      setCuenta(result);
      setUpdate(true);
    };
    const inputProps={
      // placeHolder:'Place holder',
      value:cuenta,
      onChange:onChange
    }

    useEffect(() => {
      if (typeof asiento.monto === 'string' || asiento.monto instanceof String){
        asiento.monto=parseMonto(asiento.monto);
      }
    }, [])

    function updateMonto(value){
      asiento.monto=parseMonto(value);
      if (asiento.monto)
        setMonto(asiento.monto.strFullMonto);
      else
        setMonto('');
      setUpdate(true);
      calc();
    }
  return (
    <div className='row asiento ml-3 py-2 py-md-0'>
      <Autosuggest
        suggestions={cuentasFiltradas}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={()=>setCuentasFiltradas([])}
        getSuggestionValue={(v) => {return v.nombre}}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        containerProps={{className:'col-12 col-sm-12  col-md-6 col-lg-7  col-xl-8 cuenta'}}
      />
      {/* <input id={id} className='col-12 col-sm-12  col-md-6 col-lg-7  col-xl-8 cuenta' onChange={handleChange} type="text" value={cuenta}/> */}
      <div className='col-8  col-sm-9   col-md-4 col-lg-3  col-xl-3 monto'><input className="w-100" style={{textAlign: 'right'}} onChange={(e) => updateMonto(e.target.value)} type="text" value={monto}/></div>
      <div className='col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1'>
        <AiOutlineCloseCircle className='rm-icon' onClick={() => eliminar(id)}/>
        <AiOutlinePlusCircle className='rm-icon' onClick={() => agregar(id)}/>
      </div>
    </div>
  );
}

export default Asiento;