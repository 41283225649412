import {useState} from 'react';

function EditUserPass({hide,editarDatos,usr}) {

  const [user, setUser] = useState(usr);
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Nuevos datos de acceso</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide} disabled={loading}></button>
          </div>
          <div className='modal-body'>
            <div className="form-floating">
              <input type="text" className="form-control" id="user" placeholder="Usuario" value={user} onChange={(e) => setUser(e.target.value)}  maxLength='20' autoComplete="off"/>
              <label htmlFor="user">Usuario</label>
            </div>
            <div className="form-floating pt-2">
              <input type="password" className="form-control" id="pass" placeholder="Contraseña" value={pass} onChange={(e) => setPass(e.target.value)}  maxLength='20' autoComplete="off"/>
              <label htmlFor="pass">Nueva contraseña</label>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={() => {setLoading(true);editarDatos(user,pass)}} disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
              Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditUserPass;