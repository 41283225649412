import {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {formatFecha,sortBal} from './Funciones'
import { AiOutlineReload } from "react-icons/ai";
import '../hojas-de-estilo/Auxiliar.css';

function Auxiliar({data}) {
  const params = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  if (params.get("filtro"))
    data.filtro=params.get("filtro");
  const [filtro, setFiltro] = useState(data.filtro);
  const [groupBy, setGroupBy] = useState(data.groupBy);
  const [monto, setMonto] = useState(data.monto);
  const [fechaInicio, setFechaInicio] = useState(data.fechaInicio);
  const [fechaFin, setFechaFin] = useState(data.fechaFin);


  useEffect(() => {
    window.$('.date-own-start').datepicker({
      dateFormat: 'dd/mm/yy',
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (e) => {setFechaInicio(e);data.fechaInicio=e}
    });
    window.$('.date-own-end').datepicker({
      dateFormat: 'dd/mm/yy',
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (e) => {setFechaFin(e);data.fechaFin=e}
    });
  }, []);

  function reload(){
    setLoading(true);
    window.$.ajax({
      url: '/api/auxiliar',
      type: 'GET',
      dataType: "json",
      data: {filtro:filtro, monto:monto, fechaInicio:fechaInicio, fechaFin:fechaFin,agrupar:groupBy},

      success: function (data) {
        setLoading(false);
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }

  useEffect(() => {
    if (items.length==0)
      reload();
  }, []);

  var ultimaPoliza="";
  function updateUltimaPoliza(str){
    if (str!==ultimaPoliza){
      ultimaPoliza=str;
      return true;
    }else
      return false;
  }



  return (
    <div className="container-lg">
      <div className="bg-body-secondary shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <div style={{display:'flex'}}>
          <h3 className="text-body-emphasis w-100">Auxiliar</h3>
          {!loading && <AiOutlineReload onClick={() => reload()} className='header-icon d-print-none' ></AiOutlineReload>}
          {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
        </div>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md'>
              <label className='pt-1' style={{marginRight:'10px'}}>Filtro </label>
              <input id='filtro' autoComplete='off' onChange={(e) => {setFiltro(e.target.value);data.filtro=e.target.value}} className='form-control form-control-lg input w-100' defaultValue={filtro}/>
          </div>

          <div className='col-6 col-sm'>
            <label className='pt-1' style={{marginRight:'10px'}}>Monto </label>
            <select className='form-select form-select-lg' value={monto} onChange={e => {setMonto(e.target.value);data.monto=e.target.value}} aria-label='.form-select-lg example'>
              <option value='cantidad'>Cantidad</option>
              <option value='costo'>Costo</option>
              <option value='mercado'>Mercado</option>
              {/* <option value='utilidad'>Utilidad</option> */}
              <option value='lotes'>Lotes</option>
              {/* <option value='porcentaje'>Porcentaje</option> */}
            </select>
          </div>
          <div className='col-6 col-sm'>
            <label className='pt-1' style={{marginRight:'10px'}}>Agrupar </label>
            <select className='form-select form-select-lg' value={groupBy} onChange={e => {setGroupBy(e.target.value);data.groupBy=e.target.value}} aria-label='.form-select-lg example'>
              <option value=''> </option>
              <option value='month'>Mes</option>
              <option value='year'>Año</option>
            </select>
          </div>
          <div className='col-6 col-sm'>
            <label htmlFor="fechaInicio" className='pt-1'>Fecha inicio</label>
            <input type="text" autoComplete='off' pattern="\d{2}/\d{2}/\d{4}" className="form-control form-control-lg date-own-start" id="fechaInicio" value={fechaInicio} onChange={(e) => {setFechaInicio(e.target.value);data.fechaInicio=e.target.value}}/>
          </div>
          <div className='col-6 col-sm'>
            <label htmlFor="fechaFin" className='pt-1'>Fecha fin</label>
            <input type="text" autoComplete='off' pattern="\d{2}/\d{2}/\d{4}" className="form-control form-control-lg date-own-end" id="fechaFin" value={fechaFin} onChange={(e) => {setFechaFin(e.target.value);data.fechaFin=e.target.value}}/>
          </div>
        </div>
      </div>


      <div className='container-lg mt-3 bg-body rounded-3'>
          {loading &&
          <div className='row'>
            <div className='col'>
                <div scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></div>
            </div>
            <div className='col'>
                <div scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></div>
            </div>
            <div className='col'>
                <div scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></div>
            </div>
          </div>
          }
          {!loading &&
          <>
            {items.map((item) =>
              <div className='row  border-bottom' key={item.id}>
                {updateUltimaPoliza(item.fecha+item.descripcion) ? 
                <>
                  <div className='col-3 col-lg-2 col-xl-1' ><Link to={'/edit?id='+item.poliza}>{formatFecha(item.fecha)}</Link></div>
                  <div className='col-9 col-lg-3 col-xl-3' style={{overflow: 'auto'}}><Link to={'/edit?id='+item.poliza}>{item.descripcion}</Link></div>
                </>
                :
                <>
                  <div className='d-none d-lg-block col-3 col-lg-2 col-xl-1' ><Link to={'/edit?id='+item.poliza}>{formatFecha(item.fecha)}</Link></div>
                  <div className='d-none d-lg-block col-9 col-lg-3 col-xl-3' ><Link to={'/edit?id='+item.poliza}>{item.descripcion}</Link></div>
                </>
                }
                {item.label && <div className='col-12 col-lg-3 col-xl-4 text-primary'>{item.label}</div>}
                {!item.label && <div className='col-12 col-lg-3 col-xl-4 text-primary'>{item.cuenta}</div>}
                {!item.balanceGrupo && <div className={'col-6 col-lg-2 col-xl-2 text-end '+(item.monto<0?'text-danger':'text-body-emphasis')} >{item.strMonto}</div>}
                {item.balanceGrupo && <div className='col-6 col-lg-2 col-xl-2 text-end' >{
                  item.balanceGrupo.sort(sortBal).map((monto) =>
                    <div className={(monto.monto<0?'text-danger':'text-body-emphasis')}>{monto.strMonto}</div>
                  )}</div>}
                <div className='col-6 col-lg-2 col-xl-2 text-end' >{
                  item.balance.sort(sortBal).map((monto) =>
                    <div className={(monto.monto<0?'text-danger':'text-body-emphasis')}>{monto.strMonto}</div>
                  )}
                </div>
              </div>
            )}
          </>
          }
      </div>
    </div>
  );
}

export default Auxiliar;