import {useState} from 'react';


function FileUploader({setPolizas}) {

  const [selectedFile, setselectedFile] = useState(null);

    // On file select (from the pop up)
    const onFileChange = event => {
      // Update the state
      setselectedFile(event.target.files[0]);
      onFileUpload(event.target.files[0]);
    };
 
    // On file upload (click the upload button)
    const onFileUpload = (selectedFile) => {
        // Create an object of formData
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "myFile",
            selectedFile,
            selectedFile.name
        );
 

        // Request made to the backend api
        // Send formData object
        window.$.ajax({
          url: '/api/uploadfile',
          type: 'POST',
          contentType: false,
          processData: false,
          cache: false,
          data: formData,
          success: function (result) {
            setPolizas(result);
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
          },
        })
    };
 


  return (
    <div className='m-3 w-100'>
          <div className='btn btn-primary' onClick={(e) => window.$('#file').click()}>
            Importar archivo
            <input hidden={true} type="file" id='file' onChange={onFileChange}/>
          </div>
          {/* <button onClick={onFileUpload}>
              Upload!
          </button> */}
    </div>
  );
}

export default FileUploader;