import {useState} from 'react';

function Mensaje({hide,title,msg}) {


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='modal-title '>{title}</h2>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>
          <div className='modal-body'>
            <div className='d-flex'>
                <h5 className='mx-3'>{msg}</h5>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={hide}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mensaje;