import {Link} from 'react-router-dom';

function Faq() {


  return (
    <div className='container'>
    <h1>What is adapunks.io</h1>
    <p>Adapunks.io is a decentralized application (dApp) on the Cardano BlockChain to bring utility to the ADAPunks holders.</p>
    <p>In adapunks.io you can buy and sell ADAPunks and thus help the poject to grow.</p>

    <h1 style={{marginTop: '75px'}}>Fees</h1>
    <p>Only 4%, the cheapes on market. Half is for adapunks.io to pay the bills (service) and the other half is for the AdaPunks community (royalties)</p>

    <h1 style={{marginTop: '75px'}}>Who is developing adapunks.io</h1>
    <p>One holder who wants the collection in the CNFT top 10. Lover of developing and Cardano. <a href='mailto:albert@adamail.me'>albert@adamail.me</a></p>
    </div>
  );
}

export default Faq;