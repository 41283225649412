import {useState,useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../hojas-de-estilo/Cuentas.css';
import EditCuenta from './Modals/EditCuenta';

function Cuentas({cargarCuentas}) {
  const loading = useRef(true);
  const [items, setItems] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [nombre, setNombre] = useState(null);

  function editar(n){
    setNombre(n);
    setShowEdit(true);
  }

  function editarCuenta(oldName,newName){
    window.$.ajax({
      url: '/api/cuenta',
      type: 'PUT',
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({oldName:oldName,newName:newName}),
      success: function (data) {
        reload();
        cargarCuentas();
        setShowEdit(false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }

  function reload(){
    window.$.ajax({
      url: '/api/cuentas',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }

  useEffect(() => {
    window.$.ajax({
      url: '/api/cuentas',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }, []);

  return (
    <div className="container-lg">
      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Cuentas</h3>
      </div>

      <table className='table rounded-bottom-3 bg-body'>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item) =>
              <tr key={item.nombre}>
                <td >
                  <Link to={`/auxiliar?filtro=${item.nombre}`}>{item.nombre}</Link> 
                  <button className='btn btn-outline-secondary ms-3' onClick={(e) => editar(item.nombre)}>Editar</button>
                </td>
              </tr>
            )}
          </>
          }
        </tbody>
      </table>


      {showEdit && <EditCuenta editarCuenta={editarCuenta} hide={() => setShowEdit(false)} nombre={nombre}></EditCuenta>}
    </div>
  );
}

export default Cuentas;