import {useState,useEffect} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../hojas-de-estilo/Charts.css';
import ChartEdition from './Modals/ChartEdition';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options1 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'crypto:ada',
    },
  },
};

export const options2 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'staking:ray',
    },
  },
};

export const options3 = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'crypto',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
export const asd = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => 1),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => 2),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};


function Charts() {
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [editModed, setEditModed] = useState(false);
  const [showChartEdition, setShowChartEdition] = useState(false);
  //id a editar
  const [id, setId] = useState(null);


  function loadChart1(){
    window.$.ajax({
      url: '/api/chartData?id=1',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data){
          window.$('#chartLblTotal1').html(data.nombre);
          window.$.ajax({
            url: '/api/balance',
            type: 'GET',
            dataType: "json",
            data: {nivel: 1, filtro:data.filtro, monto:data.monto, invertir:(data.invertir?true:false)},
            success: function (result) {
              window.$('#chartTotal1').html(result[result.length-1].balance[0].strMonto);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR);
              console.log(textStatus);
              console.log(errorThrown);
              if (jqXHR.status==401)
                window.location='/login';
              if (jqXHR.status==402)
                window.location='/pay-base';
            },
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  function loadChart2(){
    window.$.ajax({
      url: '/api/chartData?id=2',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data){
          window.$('#chartLblTotal2').html(data.nombre);
          window.$.ajax({
            url: '/api/balance',
            type: 'GET',
            dataType: "json",
            data: {nivel: 1, filtro:data.filtro, monto:data.monto, invertir:(data.invertir?true:false)},
            success: function (result) {
              window.$('#chartTotal2').html(result[result.length-1].balance[0].strMonto);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR);
              console.log(textStatus);
              console.log(errorThrown);
              if (jqXHR.status==401)
                window.location='/login';
              if (jqXHR.status==402)
                window.location='/pay-base';
            },
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  function loadChart3(){
    window.$.ajax({
      url: '/api/chartData?id=3',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data){
          window.$('#chartLblTotal3').html(data.nombre);
          window.$.ajax({
            url: '/api/balance',
            type: 'GET',
            dataType: "json",
            data: {nivel: 1, filtro:data.filtro, monto:data.monto, invertir:(data.invertir?true:false)},
            success: function (result) {
              window.$('#chartTotal3').html(result[result.length-1].balance[0].strMonto);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR);
              console.log(textStatus);
              console.log(errorThrown);
              if (jqXHR.status==401)
                window.location='/login';
              if (jqXHR.status==402)
                window.location='/pay-base';
            },
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  function loadChart4(){
    window.$.ajax({
      url: '/api/chartData?id=4',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data){
          window.$('#chartLblTotal4').html(data.nombre);
          window.$.ajax({
            url: '/api/balance',
            type: 'GET',
            dataType: "json",
            data: {nivel: 1, filtro:data.filtro, monto:data.monto, invertir:(data.invertir?true:false)},
            success: function (result) {
              window.$('#chartTotal4').html(result[result.length-1].balance[0].strMonto);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log(jqXHR);
              console.log(textStatus);
              console.log(errorThrown);
              if (jqXHR.status==401)
                window.location='/login';
              if (jqXHR.status==402)
                window.location='/pay-base';
            },
          });
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  function loadChart5(){
    window.$.ajax({
      url: '/api/chartData?id=5',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data)
        window.$.ajax({
          url: '/api/auxiliar',
          type: 'GET',
          dataType: "json",
          data: {filtro:data.filtro, monto:data.monto, agrupar:'month', invertir:(data.invertir?true:false)},
          success: function (result) {
            result=result.sort((a,b) => {
              if (parseInt(a.cuenta)<parseInt(b.cuenta)) return -1;
              else if (parseInt(a.cuenta)>parseInt(b.cuenta)) return 1;
              else return 0;
            });
            result=result.slice(-24);
            const labels = result.map((i) => i.label);
            options1.plugins.title.text=data.nombre;
            const d = {
              labels,
              datasets: [
                {
                  label: data.filtro,
                  data: result.map((i) => (data.acumulado?i.balance[0].monto:(i.balanceGrupo?i.balanceGrupo[0].monto:i.monto))),
                  backgroundColor: 'rgba(53, 162, 235, 0.85)',
                },
              ],
            };
            setData1(d);        
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            if (jqXHR.status==401)
              window.location='/login';
            if (jqXHR.status==402)
              window.location='/pay-base';
          },
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  function loadChart6(){
    window.$.ajax({
      url: '/api/chartData?id=6',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data)
        window.$.ajax({
          url: '/api/auxiliar',
          type: 'GET',
          dataType: "json",
          data: {filtro:data.filtro, monto:data.monto, agrupar:'month', invertir:(data.invertir?true:false)},
          success: function (result) {
            result=result.sort((a,b) => {
              if (parseInt(a.cuenta)<parseInt(b.cuenta)) return -1;
              else if (parseInt(a.cuenta)>parseInt(b.cuenta)) return 1;
              else return 0;
            });
            result=result.slice(-24);
            const labels = result.map((i) => i.label);
            options2.plugins.title.text=data.nombre;
            const d = {
              labels,
              datasets: [
                {
                  label: data.filtro,
                  data: result.map((i) => (data.acumulado?i.balance[0].monto:(i.balanceGrupo?i.balanceGrupo[0].monto:i.monto))),
                  backgroundColor: 'rgba(53, 162, 235, 0.85)',
                },
              ],
            };
            setData2(d);        
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            if (jqXHR.status==401)
              window.location='/login';
            if (jqXHR.status==402)
              window.location='/pay-base';
          },
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });

  }
  function loadChart7(){
    window.$.ajax({
      url: '/api/chartData?id=7',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        if (data)
        window.$.ajax({
          url: '/api/auxiliar',
          type: 'GET',
          dataType: "json",
          data: {filtro:data.filtro, monto:data.monto, agrupar:'month', invertir:(data.invertir?true:false)},
          success: function (result) {
            result=result.sort((a,b) => {
              if (parseInt(a.cuenta)<parseInt(b.cuenta)) return -1;
              else if (parseInt(a.cuenta)>parseInt(b.cuenta)) return 1;
              else return 0;
            });
            result=result.slice(-24);
            const labels = result.map((i) => i.label);
            options3.plugins.title.text=data.nombre;
            const d = {
              labels,
              datasets: [
                {
                  label: data.filtro,
                  data: result.map((i) => (data.acumulado?i.balance[0].monto:(i.balanceGrupo?i.balanceGrupo[0].monto:i.monto))),
                  backgroundColor: 'rgba(53, 162, 235, 0.85)',
                },
              ],
            };
            setData3(d);        
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            if (jqXHR.status==401)
              window.location='/login';
            if (jqXHR.status==402)
              window.location='/pay-base';
          },
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }

  useEffect(() => {
    loadChart1();
    loadChart2();
    loadChart3();
    loadChart4();
    loadChart5();
    loadChart6();
    loadChart7();
  }, []);


  function editar(id){
    if (editModed){
      setId(id);
      setShowChartEdition(true);
    }
  }

  function doEditar(nombre,filtro,monto,acumulado,invertir){
    window.$.ajax({
      url: '/api/chart',
      type: 'PUT',
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        id: id,
        nombre: nombre,
        filtro: filtro,
        monto: monto,
        acumulado: acumulado,
        invertir: invertir
      }),
      success: function (data) {
        if (data.result=='ok'){
          switch (id) {
            case 1:
              loadChart1();
              break;
            case 2:
              loadChart2();
              break;
            case 3:
              loadChart3();
              break;
            case 4:
              loadChart4();
              break;
            case 5:
              loadChart5();
              break;
            case 6:
              loadChart6();
              break;
            case 7:
              loadChart7();
              break;
                                                      
          }
          setShowChartEdition(false);
        }else{
          window.$('#alert-modal-body').html(data.msg);
          window.$('#alert-modal').modal('show');
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  

  return (
    <div className='container-xl'>
      <div className='mb-3' style={{display:'flex'}}>
        <h3 class="text-body-emphasis w-100"></h3>
        <button onClick={e => setEditModed(!editModed)} className="btn btn-primary">{editModed?'Finalizar':'Editar'}</button>
      </div>
      <div className='container'>
        <div  className='container'>
        <div className="row bg-body shadow-sm rounded-3 py-3">
          <div onClick={() => editar(1)} className={editModed?'col-6 col-lg-3 graphEdit':'col-6 col-lg-3'} >
            <h3 id="chartTotal1" className="text-center text-body-emphasis">...</h3>
            <div  id="chartLblTotal1" className='text-center'>...</div>
          </div>
          <div onClick={() => editar(2)} className={editModed?'col-6 col-lg-3 graphEdit':'col-6 col-lg-3'}>
            <h3 id="chartTotal2" className="text-center text-body-emphasis">...</h3>
            <div id="chartLblTotal2" className='text-center'>...</div>
          </div>
          <div  onClick={() => editar(3)}className={editModed?'col-6 col-lg-3 mt-4 mt-sm-0 graphEdit':'col-6 col-lg-3 mt-4 mt-sm-0'}>
            <h3 id="chartTotal3" className="text-center text-body-emphasis">...</h3>
            <div id="chartLblTotal3" className='text-center'>...</div>
          </div>
          <div onClick={() => editar(4)} className={editModed?'col-6 col-lg-3 mt-4 mt-sm-0 graphEdit':'col-6 col-lg-3 mt-4 mt-sm-0'}>
            <h3 id="chartTotal4" className="text-center text-body-emphasis">...</h3>
            <div id="chartLblTotal4" className='text-center'>...</div>
          </div>
        </div>
        </div>
      </div>

      {data1 && <div className={editModed?'bg-body shadow-sm rounded-3 my-3 p-1 graphEdit':'bg-body shadow-sm rounded-3 my-3 p-1'} onClick={() => editar(5)}><Bar className='pt-3' options={options1} data={data1} /></div>}
      {data2 && <div className={editModed?'bg-body shadow-sm rounded-3 my-3 p-1 graphEdit':'bg-body shadow-sm rounded-3 my-3 p-1'} onClick={() => editar(6)}><Bar className='pt-3' options={options2} data={data2} /></div>}
      {data3 && <div className={editModed?'bg-body shadow-sm rounded-3 my-3 p-1 graphEdit':'bg-body shadow-sm rounded-3 my-3 p-1'} onClick={() => editar(7)}><Bar className='pt-3' options={options3} data={data3} /></div>}


      {showChartEdition && <ChartEdition editar={doEditar} hide={() => setShowChartEdition(false)} id={id}></ChartEdition>}
    </div>
  );
}

export default Charts;