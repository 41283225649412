import {useState,useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Faq from "./components/Faq";
import Index from "./components/Index";
import Form from "./components/FormPoliza";
import ListaPolizas from "./components/ListaPolizas";
import Header from './components/Header';
import Cuentas from './components/Cuentas';
import Auxiliar from './components/Auxiliar';
import FormPolizas from './components/FormPolizas';
import LogIn from './components/LogIn';
import HeaderComercial from './components/HeaderComercial';
import Cuenta from './components/Cuenta';
import Charts from './components/Charts';
import Alta from './components/Alta';
import Balanza from './components/Balanza';
import Precios from './components/Precios';

function App() {

  const [polizas, setPolizas] = useState([]);
  const [polizasData, setPolizasData] = useState({filtro:null,filtroCuenta:null,page:0});
  const [balanceData, setBalanceData] = useState({nivel:1,monto:'cantidad'});
  const [auxiliarData, setAuxiliarData] = useState({nivel:1,monto:'cantidad'});



  window.jQuery(function($){

    $.datepicker.regional['es'] = {
      closeText: 'Cerrar',
      prevText: '&#x3C;Ant',
      nextText: 'Sig&#x3E;',
      currentText: 'Hoy',
      monthNames: ['Enero','Febrero','Marzo','Abril','Mayo','Junio',
      'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
      monthNamesShort: ['Ene','Feb','Mar','Abr','May','Jun',
      'Jul','Ago','Sep','Oct','Nov','Dic'],
      dayNames: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
      dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
      dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
      weekHeader: 'Sm',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''};
    $.datepicker.setDefaults($.datepicker.regional['es']);
  
  });
  window.$.datepicker.setDefaults( window.$.datepicker.regional[ "es" ] );
  const [cookies, setCookie] = useCookies(['session']);
  const [cuentas, setCuentas] = useState([]);
  const [selected, setSelected] = useState(null);

  function cargarCuentas(){
    window.$.ajax({
      url: '/api/cuentasMoneda',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        setCuentas(data);
        // const nuevasCuentas = [];
        // for (const item of data)
        //   nuevasCuentas.push(item.cuenta)
        // setCuentas(nuevasCuentas);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
    });
  }

  useEffect(() => {
    cargarCuentas();
  }, []);

  const cargarPolizas = (setLoading) => {
    window.$.ajax({
      url: '/api/polizas',
      type: 'GET',
      dataType: "json",
      data: {filtro:polizasData.filtro,filtroCuenta:polizasData.filtroCuenta},
      success: function (data) {
        polizasData.page=0;
        polizasData.hasMore=true;
        setPolizas(data);
        if (setLoading)
          setLoading(false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }
  const cargarMasPolizas = (setLoading) => {
    if (polizasData.hasMore){
      polizasData.page=polizasData.page+1;
      if (setLoading)
        setLoading(true);
      window.$.ajax({
        url: '/api/polizas',
        type: 'GET',
        dataType: "json",
        data: {filtro:polizasData.filtro,filtroCuenta:polizasData.filtroCuenta,page:polizasData.page},
        success: function (data) {
          setPolizas(polizas.concat(data));
          if (setLoading)
            setLoading(false);
          if (data.length<50)
            polizasData.hasMore=false;
          else
            polizasData.hasMore=true;
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
            window.location='/login';
          if (jqXHR.status==402)
            window.location='/pay-base';
        },
      });
    }
  }

  return (
    <div>
      <BrowserRouter>
        {cookies.session && <Header/>}
        {!cookies.session && <HeaderComercial/>}
        <Routes>
          <Route path='/login' element={<LogIn/>}/>
          <Route path='/alta' element={<Alta/>}/>
          <Route path='/charts' element={<Charts/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/add' element={<FormPolizas cargarCuentas={cargarCuentas} cargarPolizas={cargarPolizas}  method='POST' cuentas={cuentas}/>}/>
          <Route path='/edit' element={<FormPolizas allPolizas={polizas} cargarCuentas={cargarCuentas} cargarPolizas={cargarPolizas} method='PUT' cuentas={cuentas}/>}/>
          <Route path='/polizas' element={<ListaPolizas cargarMasPolizas={cargarMasPolizas} cargarPolizas={cargarPolizas} cargarCuentas={cargarCuentas} polizas={polizas} setPolizas={setPolizas} data={polizasData}/>}/>
          <Route path='/cuentas' element={<Cuentas  cargarCuentas={cargarCuentas}/>}/>
          <Route path='/precios' element={<Precios/>}/>
          <Route path='/cuenta' element={<Cuenta/>}/>
          <Route path='/balanza' element={<Balanza data={balanceData}/>}/>
          <Route path='/auxiliar' element={<Auxiliar data={auxiliarData}/>}/>
          <Route path="*" element={<Index />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
