import React, { useState,useEffect } from 'react';
import {Link} from 'react-router-dom';
import '../css/Header.css';
import { AiOutlinePlusCircle,AiOutlineFileAdd } from "react-icons/ai";
import { HiOutlineViewList,HiOutlineDocumentReport,HiOutlineUserCircle,HiOutlineChartBar,HiOutlineOfficeBuilding } from "react-icons/hi";


function Header() {

  const [empresas, setEmpresas] = useState([]);
  const [empresaActual, setEmpresaActual] = useState([]);



  useEffect(() => {
    window.$.ajax({
      url: '/api/empresas',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        setEmpresas(data);
        for (const e of data){
          if (e.current)
            setEmpresaActual(e.nombre);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
      },
    });
  }, []);

  function setEmpresa(empresa){
    window.$.ajax({
      url: '/api/empresa',
      type: 'PUT',
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({id:empresa}),
      success: function (data) {
        window.location.reload();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
      },
    })
  }

  return (
    <div className="container-lg">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <Link to="/" className="d-flex align-items-center me-auto link-body-emphasis text-decoration-none">
          <span className="fs-4">MiConta</span>
          <span className="fs-5 ps-4" style={{color:'gray'}}>{empresaActual}</span>
        </Link>

        <ul className="nav d-print-none">
          <li className="nav-item"><Link to="/charts" className="nav-link SuperLink"><HiOutlineChartBar className='header-icon' /></Link></li>
          <li className="nav-item"><Link to="/add" className="nav-link SuperLink"><AiOutlineFileAdd className='header-icon' /></Link></li>
          <li className="nav-item"><Link to="/polizas" className="nav-link SuperLink"><HiOutlineViewList className='header-icon' /></Link></li>

          {/* <li className="nav-item"><Link to="/fight" className="nav-link SuperLink">Fight</Link></li> */}
          {/* <li className="nav-item"><Link to="/polizas" className="nav-link SuperLink">Pólizas</Link></li> */}
          {/* <li className="nav-item"><Link to="/add" className="nav-link SuperLink">Agregar</Link></li> */}
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <HiOutlineDocumentReport className='header-icon' />
            </a>
            <ul className="dropdown-menu">
              {/* <li><Link className="dropdown-item nav-link SuperLink" to="/listFights">Fights</Link></li> */}
              <li><Link className="dropdown-item nav-link SuperLink" to="/balanza">Balanza</Link></li>
              <li><Link className="dropdown-item nav-link SuperLink" to="/auxiliar">Auxiliar</Link></li>
              <li><Link className="dropdown-item nav-link SuperLink" to="/cuentas">Cuentas</Link></li>
              <li><Link className="dropdown-item nav-link SuperLink" to="/precios">Precios</Link></li>
              <li><a className="dropdown-item nav-link SuperLink" target='_blank' href="/api/download">Descargar txt</a></li>
            </ul>
          </li>

          {/* {empresas.length>0 && */}
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <HiOutlineOfficeBuilding className='header-icon' />
              </a>
              <ul className="dropdown-menu">
                {
                  empresas.map((empresa) =>
                    <li><Link onClick={() => {setEmpresa(empresa.id)}} className="dropdown-item nav-link SuperLink" to="#">{empresa.nombre}</Link></li>
                  )
                }
              </ul>
            </li>
          {/* } */}
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <HiOutlineUserCircle className='header-icon' />
            </a>
            <ul className="dropdown-menu">
              <li><Link className="dropdown-item nav-link SuperLink" to="/cuenta">Mi cuenta</Link></li>
              <li className="nav-item"><Link onClick={() => window.$.ajax({type: "GET", url: "/api/logout"})} to="/login" className="nav-link SuperLink">Salir</Link></li>
            </ul>
          </li>
        </ul>
      </header>
    </div>
  );
}

export default Header;