import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import '../hojas-de-estilo/LogIn.css';

function Alta() {
  useEffect(() => {
    window.$("form").submit(function (event) {
      event.preventDefault();
      event.stopPropagation();

      const captcha = window.$('[name=g-recaptcha-response]');
      if (captcha.length>0 && captcha[0].value==""){
        appendAlert("Debe palomear el captcha");
        return;
      }

      var formData = {
        correo: window.$("#correo").val(),
        empresa: window.$("#empresa").val(),
        user: window.$("#user").val(),
        pass: window.$("#pass").val(),
        captcha: captcha[0].value
      };

      window.$.ajax({
        type: "POST",
        url: "/api/alta",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(formData),
        dataType: "json",
      }).done(function (data) {
        if (data.result=='ok')
          window.location='/polizas';
        else{
          appendAlert(data.msg);
        }
      });
  
      event.preventDefault();
      event.stopPropagation();
    });
    window.grecaptcha.ready(function() {
      reCaptchaDraw();
    });
  }, []);


  const appendAlert = (message) => {
    const alertPlaceholder = document.getElementById('liveAlertPlaceholder');
    alertPlaceholder.innerHTML = "";
    const wrapper = document.createElement('div')
    wrapper.innerHTML = [
      `<div class="alert alert-danger alert-dismissible" role="alert">`,
      `   <div>${message}</div>`,
      '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
      '</div>'
    ].join('')

    alertPlaceholder.append(wrapper)
  }
  var reCaptchaDraw = function() {
		try {
		    window.grecaptcha.render('reCapt', {
		        'sitekey' : '6LcYZP8kAAAAAHYNDLCtQWyqSP_ly1qeyuL6-TyV',
		        'theme' : 'light'
		      });
			} catch (exceptionVar) {}
	  };

  return (
  <div className="d-flex align-items-center py-4 bg-body-tertiary">
    <main className="form-signin w-100 m-auto">
      <form>
        <img className="mb-4" src="/logo192.png" alt="" width="75" height="75"/>
        <h1 className="h3 mb-3 fw-normal">Alta nuevo sistema</h1>

        <div id="liveAlertPlaceholder"></div>

        <div className="form-floating">
          <input type="text" className="form-control" id="correo" placeholder="nombre@ejemplo.com" autoComplete="off"/>
          <label htmlFor="correo">Correo electronico</label>
        </div>
        <div className="form-floating">
          <input type="text" className="form-control mt-1" id="empresa" placeholder="Mi empresa" autoComplete="off"/>
          <label htmlFor="empresa">Empresa</label>
        </div>
        <div className="form-floating">
          <input type="text" className="form-control mt-1" id="user" placeholder="Usuario" autoComplete="off"/>
          <label htmlFor="user">Usuario</label>
        </div>
        <div className="form-floating">
          <input type="password" className="form-control mt-1" id="pass" placeholder="Contraseña" autoComplete="off"/>
          <label htmlFor="pass">Contraseña</label>
        </div>

        {/* <div className="form-check text-start my-3">
          <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault"/>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Recordar sesión
          </label>
        </div> */}
        <div id="reCapt" className="g-recaptcha mt-1" data-sitekey="6LcYZP8kAAAAAHYNDLCtQWyqSP_ly1qeyuL6-TyV"></div>
        <input type="hidden" id="captcha"/>
        <button className="btn btn-primary w-100 py-2 mt-5" type="submit">Iniciar</button>
        <p className="mt-5 mb-3 text-body-secondary">© 2024</p>
      </form>
    </main>
  </div>
  );
}

export default Alta;