export function formatFecha(f){
    var fecha;
    if ( Object.prototype.toString.call(f) === "[object String]" )
      fecha=new Date(Date.parse(f))
    else if ( Object.prototype.toString.call(f) === "[object Number]" )
      fecha=new Date(f)
    else if ( Object.prototype.toString.call(f) === "[object Date]" )
      fecha=f;
    else
      return f;

    return (fecha.getUTCDate()<10?"0":"")+fecha.getUTCDate()+"/"+(fecha.getUTCMonth()<9?"0":"")+(fecha.getUTCMonth()+1)+"/"+fecha.getUTCFullYear();
}

export function formatFechaHora(f){
  var fecha;
  if ( Object.prototype.toString.call(f) === "[object String]" )
    fecha=new Date(Date.parse(f))
  else if ( Object.prototype.toString.call(f) === "[object Number]" )
    fecha=new Date(f)
  else if ( Object.prototype.toString.call(f) === "[object Date]" )
    fecha=f;
  else
    return f;

  return (fecha.getDate()<10?"0":"")+fecha.getDate()+"/"+(fecha.getMonth()<9?"0":"")+(fecha.getMonth()+1)+"/"+fecha.getFullYear()
          +" "+(fecha.getHours()<10?"0":"")+fecha.getHours()+":"+(fecha.getMinutes()<10?"0":"")+fecha.getMinutes()+":"+(fecha.getSeconds()<10?"0":"")+fecha.getSeconds();
}

export function sortBal(a,b){
  if (a.moneda<b.moneda) return -1;
  else if (a.moneda>b.moneda) return 1;
  else return 0;
}