import React, { useState,useEffect } from 'react';
import { AiOutlineClose,AiOutlineCopy } from "react-icons/ai";
import AgregarEmpresa from './Modals/AgregarEmpresa';
import Confirmacion from './Modals/Confirmacion';
import Mensaje from './Modals/Mensaje';
import EditUserPass from './Modals/EditUserPass';
import {formatFechaHora} from './Funciones'



function Cuenta() {

  const [reload, setReload] = useState(1);
  const [empresas, setEmpresas] = useState([]);
  const [user, setUser] = useState("Cargando...");
  const [vencimiento, setVencimiento] = useState("Cargando...");
  const [showAgregarEmpresa, setShowAgregarEmpresa] = useState(false);
  const [showEditUserPass, setShowEditUserPass] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({active:false,msg:null,data:null});
  const [msgDialog, setMsgDialog] = useState({active:false,title:null,msg:null});


  useEffect(() => {
    window.$.ajax({
      url: '/api/empresas',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        setEmpresas(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });

    window.$.ajax({
      url: '/api/user',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        setUser(data.result.usr);
        setVencimiento(formatFechaHora(data.result.vencimiento));
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });


  }, [reload]);


  function editarDatos(usr,pass){
    window.$.ajax({
      url: '/api/user',
      type: 'PUT',
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({usr:usr,pass:pass}),
      success: function (data) {
        if (data.result=='ok'){
          setReload(reload+1);
          setShowEditUserPass(false);
        }else{
          setShowEditUserPass(false);
          setMsgDialog({active:true,title:'Error',msg:data.msg})
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }

  function agregarEmpresa(nombre){
    window.$.ajax({
      url: '/api/empresa',
      type: 'POST',
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({nombre:nombre}),
      success: function (data) {
        if (data.result=='ok'){
          setReload(reload+1);
          setShowAgregarEmpresa(false);
        }else{
          setShowAgregarEmpresa(false);
          setMsgDialog({active:true,title:'Error',msg:data.msg})
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }

  function eliminar(empresa){
    if (empresas.length>1)
      setConfirmDialog({active:true,msg:'Seguro que desea eliminar la empresa '+empresa.nombre+'?',data:empresa});
    else
      setMsgDialog({active:true,title:'Error',msg:'No se puede eliminar la unica empresa.'})
  }

  return (
    <div>
      <div className='container col-xxl-8 px-4 py-5 my-3 bg-body shadow-sm rounded-3'>
        <div className='row flex-sm-row align-items-center g-5'>
          <div className="col-md-6">
            <h2 className="text-body-emphasis">Fecha de vencimiento</h2>
            <h3>{vencimiento}</h3>
            <a href='/pay-base' className="btn btn-primary mt-2 me-2">Renovar</a>
            {/* <button className="btn btn-primary mt-2 me-2">Comprar licencia</button>
            <button className="btn btn-primary mt-2">Comprar empresa</button> */}

          </div>
          <div className="col-md-6">
            <h2 className="text-body-emphasis">Datos de acceso</h2>
            <div><label className="fs-5">Usuario:</label> {user}</div>
            <div><label className="fs-5">Contraseña:</label> ******</div>
            <button className="btn btn-primary mt-2" onClick={() => setShowEditUserPass(true)}>Actualizar</button>
          </div>
        </div>
      </div>


      <div className="container  col-xxl-8  px-4 py-5 mt-5 bg-body shadow-sm rounded-3" id="icon-grid">
        <div className="pb-2 border-bottom" style={{display: 'flex'}}><h2 className=" w-100">Empresas</h2>
          <button className="btn btn-primary mt-2" onClick={() => setShowAgregarEmpresa(true)}>Agregar</button>
        </div>


        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3  g-4 py-5">
          {
            empresas.map((empresa) =>
              <div key={empresa.id} className="col d-flex align-items-start">
                <div className=' bg-body-secondary rounded-3 ps-3 pb-3 w-100'>
                  <div className="text-end"><AiOutlineClose disabled={empresas.length==1} onClick={() => eliminar(empresa)} style={{color: 'gray'}} className='tarea-icono' /></div>

                  <h3 className="fw-bold mb-0 fs-4 text-body-emphasis w-100">{empresa.nombre}</h3>
                </div>
              </div>
            )
          }

        </div>
      </div>


      
      
      {showAgregarEmpresa && <AgregarEmpresa agregar={agregarEmpresa} hide={() => setShowAgregarEmpresa(false)}></AgregarEmpresa>}
      {showEditUserPass && <EditUserPass editarDatos={editarDatos} hide={() => setShowEditUserPass(false)} usr={user}></EditUserPass>}
      {confirmDialog.active && <Confirmacion aceptar={async () => {await fetch('/api/empresa/'+confirmDialog.data.id, {method: "DELETE",});setReload(reload+1);setConfirmDialog({active:false,msg:null,data:null});}} hide={() => setConfirmDialog({active:false,msg:null,data:null})} msg={confirmDialog.msg}></Confirmacion>} 
      {msgDialog.active && <Mensaje hide={() => setMsgDialog({active:false})} title={msgDialog.title} msg={msgDialog.msg}></Mensaje>}

    </div>
  );
}

export default Cuenta;