import {useState,useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../hojas-de-estilo/Balance.css';
import { AiOutlineReload } from "react-icons/ai";
import {sortBal} from './Funciones'


function Balanza({data}) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [filtro, setFiltro] = useState(data.filtro);
  const [nivel, setNivel] = useState(data.nivel);
  const [monto, setMonto] = useState(data.monto);
  const [fechaInicio, setFechaInicio] = useState(data.fechaInicio);
  const [fechaFin, setFechaFin] = useState(data.fechaFin);
  const [invert, setInvert] = useState(data.invert);


  useEffect(() => {
    window.$('.date-own-start').datepicker({
      dateFormat: 'dd/mm/yy',
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (e) => {setFechaInicio(e);data.fechaInicio=e}
    });
    window.$('.date-own-end').datepicker({
      dateFormat: 'dd/mm/yy',
      showButtonPanel: true,
      changeMonth: true,
      changeYear: true,
      onSelect: (e) => {setFechaFin(e);data.fechaFin=e}
    });
  }, []);

  function reload(){
    setLoading(true);
    window.$.ajax({
      url: '/api/balance',
      type: 'GET',
      dataType: "json",
      data: {nivel: nivel, filtro:filtro, monto:monto, fechaInicio:fechaInicio, fechaFin:fechaFin, invertir:invert},
      success: function (data) {
        setLoading(false);
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    });
  }

  useEffect(() => {
    if (items.length==0)
      reload();
  }, []);

  return (
    <div className="container-lg">
      <div className="bg-body-secondary  shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <div style={{display:'flex'}}>
          <h3 className="text-body-emphasis w-100">Balanza</h3>
          {!loading && <AiOutlineReload onClick={() => reload()} className='header-icon d-print-none' ></AiOutlineReload>}
          {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
        </div>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md'>
              <label className='pt-1' style={{marginRight:'10px'}}>Filtro </label>
              <input id='filtro' autoComplete='off' onChange={(e) => {setFiltro(e.target.value);data.filtro=e.target.value}} className='form-control form-control-lg input w-100' defaultValue={filtro}/>
          </div>
          <div className='col-6 col-sm'>
            <label className='pt-1' style={{marginRight:'10px'}}>Nivel </label>
            <select className='form-select form-select-lg' value={nivel} onChange={e => {setNivel(parseInt(e.target.value));data.nivel=parseInt(e.target.value)}} aria-label='.form-select-lg example'>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
            </select>
          </div>
          <div className='col-6 col-sm'>
            <label className='pt-1' style={{marginRight:'10px'}}>Monto </label>
            <select className='form-select form-select-lg' value={monto} onChange={e => {setMonto(e.target.value);data.monto=e.target.value}} aria-label='.form-select-lg example'>
              <option value='cantidad'>Cantidad</option>
              <option value='costo'>Costo</option>
              <option value='mercado'>Mercado</option>
              <option value='utilidad'>Utilidad</option>
              <option value='lotes'>Lotes</option>
              {/* <option value='porcentaje'>Porcentaje</option> */}
            </select>
          </div>
          <div className='col-6 col-sm'>
            <label htmlFor="fechaInicio" className='pt-1'>Fecha inicio</label>
            <input type="text" autoComplete='off' pattern="\d{2}/\d{2}/\d{4}" className="form-control form-control-lg date-own-start" id="fechaInicio" value={fechaInicio} onChange={(e) => {setFechaInicio(e.target.value);data.fechaInicio=e.target.value}}/>
          </div>
          <div className='col-6 col-sm'>
            <label htmlFor="fechaFin" className='pt-1'>Fecha fin</label>
            <input type="text" autoComplete='off' pattern="\d{2}/\d{2}/\d{4}" className="form-control form-control-lg date-own-end" id="fechaFin" value={fechaFin} onChange={(e) => {setFechaFin(e.target.value);data.fechaFin=e.target.value}}/>
          </div>
          <div className='col-12 col-sm-12 col-md'>
            <input class="form-check-input" type="checkbox" value={invert} id="invert" onChange={(e) => {setInvert(e.target.checked);data.invert=e.target.checked}}/>
            <label class="form-check-label" for="invert">
              Invertir
            </label>
          </div>
        </div>
      </div>


      {loading &&
      <div className='container-lg mt-3 bg-body'>
          <div>
            <div className='row  border-bottom'>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>                
            </div>
            <div className='row  border-bottom'>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>                
            </div>
            <div className='row  border-bottom'>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>
              <div className='col'>
                <div className='placeholder-glow'><span className='placeholder col-12'></span></div>
              </div>                
            </div>
          </div>
      </div>
      }

      {!loading &&
      <div className='container-lg mt-3 bg-body rounded-3 py-3'>
        {items.map((item) =>
          <div id='balance'>
            <div className='row  border-bottom'>
              <div className='col'>{
              item.balance.sort(sortBal).map((b) =>
                <div className={'text-end '+(b.monto<0?'text-danger':'text-body-emphasis')}>{b.strMonto}</div>
              )}
              </div>
              <div className={'col text-primary'}><Link to={`/auxiliar?filtro=${item.cuenta}`}  dangerouslySetInnerHTML={{__html: item.nombre}}></Link></div>
            </div>
          </div>
        )}
      </div>
      }
    </div>
  );
}

export default Balanza;