import {useState} from 'react';

function EditCuenta({hide,editarCuenta,nombre}) {

  const [nuevoNombre, setNuevoNombre] = useState(nombre);
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  function updateCuenta(newValue){
    const result = newValue.replace(/[^a-z:0-9-_]/gi, '');
    setNuevoNombre(result);
  }


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Actualizacion de cuenta</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide} disabled={loading}></button>
          </div>
          <div className='modal-body'>
            <div className="form-floating">
              <input type="text" className="form-control" id="user" placeholder="Usuario" value={nuevoNombre} onChange={(e) => updateCuenta(e.target.value)}  autoComplete="off"/>
              <label htmlFor="user">Nombre</label>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={() => {setLoading(true);editarCuenta(nombre,nuevoNombre)}} disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>}
              Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCuenta;