import React, { useState,useEffect, useRef, useCallback } from 'react';
import Poliza from './Poliza';
import '../hojas-de-estilo/ListaDeTareas.css';
import {formatFecha} from './Funciones'
import Loading from './Loading';
import { AiOutlineReload } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Confirmacion from './Modals/Confirmacion';


function ListaPolizas({polizas, setPolizas, cargarPolizas, cargarMasPolizas, cargarCuentas, data}) {

  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({active:false,msg:null,data:null});

  const [hasMore, setHasMore] = useState(true);
  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        console.log("more data...");
        cargarMasPolizas(setLoading);
        // const newData = {...data};
        // newData.page=newData.page+1;
        //reload(newData);
      }
    })
    if (node) observer.current.observe(node)
  }, [loading]);

  async function deleteData(url) {
    const response = await fetch(url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    });
  }

  const eliminarPoliza = data => {
    setConfirmDialog({active:true,msg:'Seguro que desea eliminar la poliza: '+data.descripcion,data:data});

  }
  const doEliminarPoliza = async id => {
    await deleteData("poliza/"+id);
    const polizasActualizadas = polizas.filter(tarea => tarea.id !== id);
    setPolizas(polizasActualizadas);
    setConfirmDialog({active:false,msg:null,data:null});
    cargarCuentas();
  }

  useEffect(() => {
    if (polizas.length==0){
      setLoading(true);
      cargarPolizas(setLoading);
    }

  }, []);

  return (
    <div className="container-lg">
      <div className="bg-body-secondary shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <div style={{display:'flex'}}>
          <h3 className="text-body-emphasis w-100">Polizas</h3>
          {!loading && <AiOutlineReload onClick={() => {setLoading(true);cargarPolizas(setLoading)}} className='header-icon' ></AiOutlineReload>}
          {loading && <div className='spinner-border' style={{width: '23px',height: '23px',marginTop:'1px'}} role='status'><span className='visually-hidden'>Loading...</span></div>}
        </div>
        <div className='row'>
          <div className='col'>
              <label className='pt-1' style={{marginRight:'10px'}}>Filtro por descripcion </label>
              <input id='filtro' autoComplete='off' onChange={(e) => {data.filtro=e.target.value}} className='form-control form-control-lg input w-100' defaultValue={data.filtro}/>
          </div>
          <div className='col'>
              <label className='pt-1' style={{marginRight:'10px'}}>Filtro por cuenta </label>
              <input id='filtro' autoComplete='off' onChange={(e) => {data.filtroCuenta=e.target.value}} className='form-control form-control-lg input w-100' defaultValue={data.filtroCuenta}/>
          </div>
        </div>
      </div>

      <div className='tareas-lista-contenedor'>
        {
          polizas.map((poliza,index) =>
          <div  className={'tarea-contenedor shadow-sm'} ref={polizas.length === index + 1 ? lastElementRef : null}>
            <Poliza
              key={poliza.id}
              id={poliza.id}
              
              fecha={formatFecha(poliza.fecha)}
              descripcion={poliza.descripcion}
              asientos={poliza.asientos}
              completada={poliza.completada}
              eliminarPoliza={eliminarPoliza} 
              />
            </div>
          )
        }
        {polizas.length==0 && !loading && <div className="container"><div className='alert alert-warning mt-2' role='alert'>No hay ninguna poliza.</div></div>}
        {loading && <Loading/>}
      </div>


      {confirmDialog.active && <Confirmacion aceptar={async () => {doEliminarPoliza(confirmDialog.data.id)}} hide={() => setConfirmDialog({active:false,msg:null,data:null})} msg={confirmDialog.msg}></Confirmacion>}      
      
    </div>
  );
}

export default ListaPolizas;