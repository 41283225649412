import {useState,useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../hojas-de-estilo/Cuentas.css';
import {formatFecha} from './Funciones'


function Precios({cargarCuentas}) {
  const loading = useRef(true);
  const [items, setItems] = useState([]);


  useEffect(() => {
    window.$.ajax({
      url: '/api/precios',
      type: 'GET',
      dataType: "json",
      success: function (data) {
        loading.current=false;
        setItems(data);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        if (jqXHR.status==401)
          window.location='/login';
        if (jqXHR.status==402)
          window.location='/pay-base';
      },
    })
  }, []);

  return (
    <div className="container-lg">
      <div className="bg-body shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
        <h3 className="text-body-emphasis">Precios</h3>
      </div>

      <table className='table rounded-bottom-3 bg-body'>
        <tbody>
          {loading.current &&
          <>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
            <tr>
                <td scope='col'><div className='placeholder-glow'><span className='placeholder col-10'></span></div></td>
            </tr>
          </>
          }
          {!loading.current &&
          <>
            {items.map((item,index) =>
              <tr key={index}>
                <td >
                  <label style={{width:'75px'}}>{item.moneda}</label>
                  <label style={{width:'100px'}}>{formatFecha(item.fecha)}</label>
                  <label style={{width:'75px',textAlign: 'right'}}>$ {parseFloat(item.precio).toLocaleString("en-US")}</label>
                </td>
              </tr>
            )}
          </>
          }
        </tbody>
      </table>
    </div>
  );
}

export default Precios;