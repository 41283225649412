import {useRef, useState} from 'react';

function AutoBalance({hide,autoAgregar}) {

  const [filtro, setFiltro] = useState(null);
  const [metodo, setMetodo] = useState('peps');
  const [amount, setAmount] = useState(0);


  function callAutoagregar(){
    if (filtro){
      const splited = amount.split(' ');
      autoAgregar(filtro,metodo,parseFloat(splited[0]),(splited.length>1?splited[1]:null));
    }else
      alert('Debe espesificar un filtro');
  }


  return (
    <div className='modal show fade' style={{display:'block',backgroundColor: 'rgba(0,0,0,0.8)'}}  data-bs-backdrop='static' tabIndex='-1' aria-labelledby='sellModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>Balance inteligente</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={hide}></button>
          </div>

          <div id='fight-modal-body' className='modal-body'>
            <div className="bg-body-secondary  shadow-sm p-3 rounded-top-3 adaPunksArenaTitle">
              <div className='row'>
                <div className='col-12 col-sm-12 col-md'>
                    <label className='pt-1' style={{marginRight:'10px'}}>Filtro </label>
                    <input id='filtro' autoComplete='off' onChange={(e) => {setFiltro(e.target.value)}} className='form-control form-control-lg input w-100' defaultValue={filtro}/>
                </div>
                <div className='col-6 col-sm'>
                  <label className='pt-1' style={{marginRight:'10px'}}>Metodo </label>
                  <select className='form-select form-select-lg' value={metodo} onChange={e => {setMetodo(e.target.value)}} aria-label='.form-select-lg example'>
                    <option value='peps'>PEPS</option>
                    <option value='ueps'>UEPS</option>
                    <option value='menor'>Menor precio</option>
                    <option value='mayor'>Mayor precio</option>
                  </select>
                </div>
                <div className='col-6 col-sm'>
                  <label className='pt-1' style={{marginRight:'10px'}}>Monto </label>
                  <input id='filtro' autoComplete='off' onChange={(e) => {setAmount(e.target.value)}} className='form-control form-control-lg input w-100' defaultValue={amount}/>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={callAutoagregar}>Agregar</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AutoBalance;