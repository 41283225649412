import {useState} from 'react';
import Asiento from './Asiento';
import { v4 as uuidv4 } from 'uuid';

function Asientos({cuentas, asientos, setAsientos, calc, autoAgregar, setUpdate}) {

  function agregar(id){
    const index = asientos.findIndex((obj) => obj.id === id)+1;
    var nuevosAsientos = [
      ...asientos.slice(0, index),
      {id:uuidv4(),cuenta:"",monto:null},
      ...asientos.slice(index)
    ];
    setAsientos(nuevosAsientos);
    setUpdate(true);
  }

  function eliminar(id){
    const nuevosAsientos = [...asientos];
    const index = nuevosAsientos.findIndex((obj) => obj.id === id);
    if (index > -1) {
      nuevosAsientos.splice(index, 1);
    }

    if (nuevosAsientos.length==0)
      nuevosAsientos.push({id:uuidv4(),cuenta:"",monto:null});
    setAsientos(nuevosAsientos);
    setUpdate(true);
  }

  return (
    <div className="container">
      <div className='bg-body-tertiary shadow-sm mt-3 p-3 rounded-3'>
        <div style={{display: 'flex'}}>
          <h2 class="text-body-emphasis w-100">Asientos</h2>
          <button className='btn btn-primary' style={{height: '40px'}} onClick={autoAgregar}>*</button>
        </div>
        {
          asientos.map((item,index) =>
            <Asiento
              key={item.id}
              id={item.id} 
              asiento={item}
              cuentas={cuentas}
              eliminar={eliminar}
              agregar={agregar}
              calc={calc}
              setUpdate={setUpdate}
            >
            </Asiento >
          ) 
        }
      </div>
  </div>
  );
}

export default Asientos;